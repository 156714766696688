import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchMidstockList } from "./MidstockListPostRedux";

interface MidstockAddPostState {
  midstockAddDraft: {
    midstockId: string,
    amountToAdd: number,
    message?: string,
  },

  dialogOpen: boolean,

  dialogInProgressAddOpen: boolean
  dialogInProgressFinishOpen: boolean

  status: APIRequestStatus,
  error: string | null,
}

const initialState: MidstockAddPostState = {
    midstockAddDraft: {
        midstockId: '',
        amountToAdd: 0,
    },

    dialogOpen: false,

    dialogInProgressAddOpen: false,
    dialogInProgressFinishOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'midstockStockAdd',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.midstockAddDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.midstockAddDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        },
        updateDialogInProgressAddOpen(state, action) {
          state.dialogInProgressAddOpen = action.payload;
        },
        updateDialogInProgressFinishOpen(state, action) {
          state.dialogInProgressFinishOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postMidstockStockAdd.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postMidstockStockAdd.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postMidstockStockAdd.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
          .addCase(postMidstockStockAddPayload.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postMidstockStockAddPayload.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
              state.dialogInProgressAddOpen = false;
              state.dialogInProgressFinishOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postMidstockStockAddPayload.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postMidstockStockAdd = createAsyncThunk('midstockStockAdd/postMidstockStockAdd', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/midstock/add',
      data: state.midstockStockAdd.midstockAddDraft,
    });

    thunkAPI.dispatch(fetchMidstockList());

    return response.data
})

export const postMidstockStockAddPayload = createAsyncThunk('midstockStockAdd/postMidstockStockAddPayload', async (arg: any, thunkAPI) => {
  const state: any = thunkAPI.getState();

  const response = await axios({
    method: 'post',
    url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/midstock/add',
    data: arg,
  });

  thunkAPI.dispatch(fetchMidstockList());

  return response.data
})

export const { updateVal, updateDialogOpen, updateDialogInProgressAddOpen, updateDialogInProgressFinishOpen } = slice.actions

export default slice.reducer