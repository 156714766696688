import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import OrderItemLabels, { PDFViewBrowser, PDFViewBrowserCourier, PDFViewBrowserShipment } from './content/applications/Orders/OrderShippingLabel';
import { PDFViewer } from '@react-pdf/renderer';
import DailyKPIs from './content/applications/Orders/DailyKPIs';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const Orders = Loader(
  lazy(() => import('src/content/applications/Orders'))
);
const InventoryRequests = Loader(
  lazy(() => import('src/content/applications/InventoryRequests'))
);
const ClothPieces = Loader(
  lazy(() => import('src/content/applications/ClothPieces'))
);
const ProductList = Loader(
  lazy(() => import('src/content/applications/ProductList'))
);
const RegularSizeStock = Loader(
  lazy(() => import('src/content/applications/RegularSizeStock'))
);
const UnitItems = Loader(
  lazy(() => import('src/content/applications/UnitItems'))
);
const Midstock = Loader(
  lazy(() => import('src/content/applications/Midstock'))
);
const FileList = Loader(
  lazy(() => import('src/content/applications/FileList'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);
const UserList = Loader(
  lazy(() => import('src/content/applications/UserList'))
);
const ServiceTickets = Loader(
  lazy(()  => import('src/content/applications/ServiceTickets'))
);
const CategoryReviews = Loader(
  lazy(()  => import('src/content/applications/CategoryReview'))
);
const CollectionList = Loader(
  lazy(()  => import('src/content/applications/CollectionList'))
);
const ProductAnalytics = Loader(
  lazy(()  => import('src/content/applications/ProductAnalytics'))
);


// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Orders />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'orders-and-requests',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="orders" replace />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'inventory-requests',
        element: <InventoryRequests />
      },
      {
        path: 'service-tickets',
        element: <ServiceTickets />
      }
    ]
  },
  {
    path: 'daily-kpis',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="revenue" replace />
      },
      {
        path: 'revenue',
        element: <DailyKPIs />
      },
    ]
  },
  {
    path: 'inventory',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="cloth-pieces" replace />
      },
      {
        path: 'cloth-pieces',
        element: <ClothPieces />
      },
      {
        path: 'unit-items',
        element: <UnitItems />
      },
      {
        path: 'regular-size-stock',
        element: <RegularSizeStock />
      },
      {
        path: 'midstock',
        element: <Midstock />
      },
    ]
  },
  {
    path: 'products',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="product-list" replace />
      },
      {
        path: 'product-list',
        element: <ProductList />
      },
      {
        path: 'user-list',
        element: <UserList />
      },
      {
        path: 'category-reviews',
        element: <CategoryReviews />
      },
      {
        path: 'collection-list',
        element: <CollectionList />
      },
      {
        path: 'analytics',
        element: <ProductAnalytics />
      }
    ]
  },
  {
    path: 'files',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="files-list" replace />
      },
      {
        path: 'files-list',
        element: <FileList />
      },
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: '/preview-sticker',
    element: <PDFViewBrowser />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: '/preview-sticker-shipment',
    element: <PDFViewBrowserShipment />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: '/preview-sticker-courier',
    element: <PDFViewBrowserCourier />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
