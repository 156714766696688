import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchUnitItemsList } from "./UnitItemListPostRedux";

export interface UnitItemAddPostState {
  unitItemAddDraft: {
    unitItemId: string,
    amountToAdd: number,
  },

  dialogOpen: boolean,

  dialogInProgressAddOpen: boolean
  dialogInProgressFinishOpen: boolean

  status: APIRequestStatus,
  error: string | null,
}

const initialState: UnitItemAddPostState = {
    unitItemAddDraft: {
        unitItemId: '',
        amountToAdd: 0,
    },

    dialogOpen: false,

    dialogInProgressAddOpen: false,
    dialogInProgressFinishOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'unitItemStockAdd',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.unitItemAddDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.unitItemAddDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        },
        updateDialogInProgressAddOpen(state, action) {
          state.dialogInProgressAddOpen = action.payload;
        },
        updateDialogInProgressFinishOpen(state, action) {
          state.dialogInProgressFinishOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postUnitItemStockAdd.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUnitItemStockAdd.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postUnitItemStockAdd.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
          .addCase(postUnitItemStockAddPayload.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUnitItemStockAddPayload.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
              state.dialogInProgressAddOpen = false;
              state.dialogInProgressFinishOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postUnitItemStockAddPayload.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postUnitItemStockAdd = createAsyncThunk('unitItemStockAdd/postUnitItemStockAdd', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/unititem/add',
      data: state.unitItemStockAdd.unitItemAddDraft,
    });

    thunkAPI.dispatch(fetchUnitItemsList());

    return response.data
})

export const postUnitItemStockAddPayload = createAsyncThunk('unitItemStockAdd/postUnitItemStockAddPayload', async (arg: any, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/unititem/add',
      data: arg,
    });

    thunkAPI.dispatch(fetchUnitItemsList());

    return response.data
})

export const { updateVal, updateDialogOpen, updateDialogInProgressAddOpen, updateDialogInProgressFinishOpen } = slice.actions

export default slice.reducer